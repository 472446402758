
/*
// swagger-custom.css

/* Operation (HTTP method) colors */
.swagger-ui .opblock-get .opblock-summary-method {
  background-color: #61affe;
}

.swagger-ui .opblock-post .opblock-summary-method {
  background-color: #49cc90;
}

.swagger-ui .opblock-put .opblock-summary-method {
  background-color: #fca130;
}

.swagger-ui .opblock-delete .opblock-summary-method {
  background-color: #f93e3e;
}

.swagger-ui .opblock-patch .opblock-summary-method {
  background-color: #50e3c2;
}

/* Operation block background colors */
.swagger-ui .opblock-get {
  background: rgba(97, 175, 254, 0.1);
}

.swagger-ui .opblock-post {
  background: rgba(73, 204, 144, 0.1);
}

.swagger-ui .opblock-put {
  background: rgba(252, 161, 48, 0.1);
}

.swagger-ui .opblock-delete {
  background: rgba(249, 62, 62, 0.1);
}

.swagger-ui .opblock-patch {
  background: rgba(80, 227, 194, 0.1);
}

/* Response section colors */
.swagger-ui .response-col_status {
  color: #3b4151;
}

.swagger-ui .response-col_description__inner div.markdown {
  color: #3b4151;
}

/* Schema section colors */
.swagger-ui .model-box {
  background-color: rgba(0, 0, 0, 0.1);
}

.swagger-ui .property.primitive {
  color: #55a;
}

/* Tags section colors */
.swagger-ui .opblock-tag {
  background-color: #f0f0f0;
}

.swagger-ui .opblock-tag:hover {
  background-color: #e8e8e8;
}
