@import "icons.css";


:root {
    --ct-body-font-family: Nunito, sans-serif;
  --ct-body-font-size: 0.9rem;
  --ct-body-font-weight: 400;
  --ct-body-line-height: 1.5;
  --ct-body-color: #6c757d;
  --ct-body-bg: #f9f9f9;
  --ct-border-color: #dee2e6;
  --ct-text-muted: #98a6ad;
  --pliable-yellow: #FF9F00;
  --pliable-pliable: var(--pliable-yellow);
  --pliable-yellow-light: rgb(255, 239, 214);
  --pliable-yellow-muted: #c37900;
  --pliable-yellow-light: #fdf5e7;
  --pliable-navy: #0f0e31;
  --pliable-blue: #00A1E0;
  --pliable-blue-hover: #00a0e0cd;
  --pliable-secondary: #6c757d;
  
  --pliable-purple: #68357A;
  --pliable-success: #1BD77D;
  --pliable-blue-bg: #d5f4ff;
  --ct-modal-backdrop-bg: #313a46;
  --ct-form-check-input-bg: #fff;
  --ct-form-check-input-border: 1px solid #666;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--pliable-yellow);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-menu-item: #6c757d;
  --ct-menu-item-hover: #313a46;
  --ct-primary: #00A1E0;
  --ct-secondary: #6c757d;
  --ct-success: #1BD77D;
  --ct-success-bg: #efffe1;
  --ct-info: #39afd1;
  --ct-info-bg: #c8eef9;
  --ct-warning: #FFE500;
  --ct-warning-bg: #FFFBD7;

  --ct-danger: #EA4335;
  --ct-danger-bg: #FEF2F1;
  --ct-light: #eef2f7;
  --ct-extra-light: #f8f9fa;
  --ct-dark: #313a46;
  --ct-light-gray: #BABABA33;
  --ct-primary-rgb: 114, 124, 245;
  --ct-secondary-rgb: 108, 117, 125;
  --ct-success-rgb: 107, 205, 29;
  --ct-info-rgb: 57, 175, 209;
  --ct-warning-rgb: 255, 229, 0;
  --ct-danger-rgb: 234, 67, 53;
  --ct-light-rgb: 238, 242, 247;
  --ct-dark-rgb: 49, 58, 70;
  --ct-highlight: #CFF4B2;

  --pliable-dark: rgba(var(--ct-dark-rgb));

}

.well{margin:0}

small.form-text {
  line-height: 13px;
  display: block;
}
.hover-control .hover-only {
  visibility: hidden;
}

.hover-control:hover .hover-only {
  visibility: visible;
}


hr {
  margin: 2rem 0;
}


.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.bg-primary {
  --ct-bg-opacity: 1;
  background-color: var(--ct-primary) !important;
}

.text-pliable {
  color: var(--pliable-yellow) !important;
}

.text-purple {
  color: var(--pliable-purple) !important;
}

.bg-pliable {
  --ct-bg-opacity: 1;
  color: white !important;
  background-color: var(--pliable-yellow) !important;
}

.bg-purple {
  --ct-bg-opacity: 1;
  color: white !important;
  background-color: var(--pliable-purple) !important;
}

.bg-secondary {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-secondary-rgb), var(--ct-bg-opacity)) !important;
}

.bg-success {
  --ct-bg-opacity: 1;
  background-color: var(--ct-success) !important;
  color: white !important;
}

.toast-header.bg-success {
  background-color: var(--ct-success) !important;
}

.bg-info {
  --ct-bg-opacity: 1;
  background-color: var(--pliable-blue) !important;
}

.bg-warning {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-warning-rgb), var(--ct-bg-opacity)) !important;
  color: black;
}

.bg-danger {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-danger-rgb), var(--ct-bg-opacity)) !important;
}

.bg-danger-light {
  --ct-bg-opacity: 1;
  background-color: var(--ct-danger-bg) !important;
}

.bg-light {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-light-rgb), var(--ct-bg-opacity)) !important;
  color: #555;
}

.bg-dark {
  --ct-bg-opacity: 1;
  color: white !important;
  background-color: rgba(var(--ct-dark-rgb), var(--ct-bg-opacity)) !important;
}

.bg-dark .form-control {
  background-color: rgba(var(--ct-dark-rgb), var(--ct-bg-opacity)) !important;
  color: white;
}

.bg-dark .icon-button:hover {
  color: #ccc !important;
}

.bg-dark label {
  color: #ccc !important;
}

.bg-black {
  --ct-bg-opacity: 1;
  background-color: rgba(var(--ct-black-rgb), var(--ct-bg-opacity)) !important;
}

.bg-white {
  background-color: white !important;
}

.bg-navy {
  background-color: var(--pliable-navy) !important;

}

.text-12 {
  font-size: 12px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-15 {
  font-size: 15px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-36 {
  font-size: 36px !important;
}

.text-header {
  font-family: "Poppins" !important;
  font-size: 18px !important;
  font-weight: 600;
}

.shadow-box {
  border: solid 1px var(--ct-border-color);
  border-radius: 5px;
  background-color: white;

  box-shadow: 0px 2px 3px 0px #0000001A;
}

.shadow-box.active {
  outline: solid 2px var(--pliable-blue);
}

.shadow-box.action:hover {
  background-color: #eee;
  cursor: pointer;
}


.container {
  flex: 1;
  padding: 0px;
  max-width: 100%;
  height: 100%;
}

.round-input {
  border-radius: 20px;
}

.container .content-inner {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.container .content-inner.scroll-horizontal {
  overflow-x: auto;
  width: auto;
}

.noscroll-h {
  overflow-x: hidden !important;
}

.scroll-vertical {
  overflow-y: auto !important;
  height: 100%;
  max-height: 100%;
  flex: 1;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.no-bold {
  font-weight: 400 !important;
}

.alert-primary {
  color: #6c76e9;
  background-color: rgba(114, 124, 245, 0.18);
  border-color: rgba(114, 124, 245, 0.25);
}
.alert-primary .alert-link {
  color: #5057ac;
}

.alert-secondary {
  color: #676f77;
  background-color: rgba(108, 117, 125, 0.18);
  border-color: rgba(108, 117, 125, 0.25);
}
.alert-secondary .alert-link {
  color: #4c5258;
}

.alert-success {
  color: #0ac58f;
  background-color: rgba(10, 207, 151, 0.18);
  border-color: rgba(10, 207, 151, 0.25);
}
.alert-success .alert-link {
  color: #07916a;
}

.alert-info {
  color: #36a6c7;
  background-color: rgba(57, 175, 209, 0.18);
  border-color: rgba(57, 175, 209, 0.25);
}
.alert-info .alert-link {
  color: #287b92;
}

.alert-warning {
  color: #f2b300;
  background-color: rgba(255, 188, 0, 0.18);
  border-color: rgba(255, 188, 0, 0.25);
}
.alert-warning .alert-link {
  color: #b38400;
}

.alert-danger {
  color: #ee5776;
  background-color: rgba(250, 92, 124, 0.18);
  border-color: rgba(250, 92, 124, 0.25);
}
.alert-danger .alert-link {
  color: #af4057;
}

.alert-light {
  color: #e2e6eb;
  background-color: rgba(238, 242, 247, 0.18);
  border-color: rgba(238, 242, 247, 0.25);
}
.alert-light .alert-link {
  color: #a7a9ad;
}

.alert-dark {
  color: #2f3743;
  background-color: rgba(49, 58, 70, 0.18);
  border-color: rgba(49, 58, 70, 0.25);
}
.alert-dark .alert-link {
  color: #222931;
}

a.colorize {
  color: var(--ct-primary) !important;
}

a.no-color {
  color: inherit !important;
}


.text-end {
  text-align: right;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.small-text {
  font-size: 12px !important;
}

.action-icon {
  color: #98a6ad;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
  background: none;
  border: none;
}
.action-icon:hover {
  color: #6c757d;
}


.list-group-item.overflow-auto {
  overflow: auto !important;
}
.list-group-item.active {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}

.list-group-item.active .text-muted {
  color: white !important;
}

.list-group-item-action:hover {
  cursor: pointer;
}

.text-primary {
    --ct-text-opacity: 1;
    color: var(--ct-primary) !important;
  }
  
  .text-secondary {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-secondary-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-success {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-success-rgb), var(--ct-text-opacity)) !important;
  }

  .text-pliable {
    --ct-text-opacity: 1;
    color: --pliable-yellow !important;
  }
  
  .text-info {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-info-rgb), var(--ct-text-opacity)) !important;
  }

  .text-white {
    color: white !important;
  }
  
  .text-warning {
    --ct-text-opacity: 1;
    color: var(--ct-warning) !important;
  }
  
  .text-danger {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-danger-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-light {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-light-rgb), var(--ct-text-opacity)) !important;
  }
  
  .text-dark {
    --ct-text-opacity: 1;
    color: rgba(var(--ct-dark-rgb), var(--ct-text-opacity)) !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .m-1 {
    margin: 0.375rem !important;
  }
  
  .m-2 {
    margin: 0.75rem !important;
  }
  
  .m-3 {
    margin: 1.5rem !important;
  }
  
  .m-4 {
    margin: 2.25rem !important;
  }
  
  .m-5 {
    margin: 4.5rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .mx-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  
  .mx-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  
  .mx-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  
  .mx-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  
  .mx-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .my-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  
  .my-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  
  .my-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  
  .my-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  
  .my-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .mt-1 {
    margin-top: 0.375rem !important;
  }
  
  .mt-2 {
    margin-top: 0.75rem !important;
  }
  
  .mt-3 {
    margin-top: 1.5rem !important;
  }
  
  .mt-4 {
    margin-top: 2.25rem !important;
  }
  
  .mt-5 {
    margin-top: 4.5rem !important;
  }
  
  .mt-auto {
    margin-top: auto !important;
  }
  
  .me-0 {
    margin-right: 0 !important;
  }
  
  .me-1 {
    margin-right: 0.375rem !important;
  }
  
  .me-2 {
    margin-right: 0.75rem !important;
  }
  
  .me-3 {
    margin-right: 1.5rem !important;
  }
  
  .me-4 {
    margin-right: 2.25rem !important;
  }
  
  .me-5 {
    margin-right: 4.5rem !important;
  }
  
  .me-auto {
    margin-right: auto !important;
  }
  
  .mb-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-1 {
    margin-bottom: 0.375rem !important;
  }
  
  .mb-2 {
    margin-bottom: 0.75rem !important;
  }
  
  .mb-3 {
    margin-bottom: 1.5rem !important;
  }
  
  .mb-4 {
    margin-bottom: 2.25rem !important;
  }
  
  .mb-5 {
    margin-bottom: 4.5rem !important;
  }
  
  .mb-auto {
    margin-bottom: auto !important;
  }
  
  .ms-0 {
    margin-left: 0 !important;
  }
  
  .ms-1 {
    margin-left: 0.375rem !important;
  }
  
  .ms-2 {
    margin-left: 0.75rem !important;
  }
  
  .ms-3 {
    margin-left: 1.5rem !important;
  }
  
  .ms-4 {
    margin-left: 2.25rem !important;
  }
  
  .ms-5 {
    margin-left: 4.5rem !important;
  }
  
  .ms-auto {
    margin-left: auto !important;
  }
  
  .m-n1 {
    margin: -0.375rem !important;
  }
  
  .m-n2 {
    margin: -0.75rem !important;
  }
  
  .m-n3 {
    margin: -1.5rem !important;
  }
  
  .m-n4 {
    margin: -2.25rem !important;
  }
  
  .m-n5 {
    margin: -4.5rem !important;
  }
  
  .mx-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  
  .mx-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  
  .mx-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  
  .mx-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  
  .mx-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  
  .my-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  
  .my-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  
  .my-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  
  .my-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  
  .my-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  
  .mt-n1 {
    margin-top: -0.375rem !important;
  }
  
  .mt-n2 {
    margin-top: -0.75rem !important;
  }
  
  .mt-n3 {
    margin-top: -1.5rem !important;
  }
  
  .mt-n4 {
    margin-top: -2.25rem !important;
  }
  
  .mt-n5 {
    margin-top: -4.5rem !important;
  }
  
  .me-n1 {
    margin-right: -0.375rem !important;
  }
  
  .me-n2 {
    margin-right: -0.75rem !important;
  }
  
  .me-n3 {
    margin-right: -1.5rem !important;
  }
  
  .me-n4 {
    margin-right: -2.25rem !important;
  }
  
  .me-n5 {
    margin-right: -4.5rem !important;
  }
  
  .mb-n1 {
    margin-bottom: -0.375rem !important;
  }
  
  .mb-n2 {
    margin-bottom: -0.75rem !important;
  }
  
  .mb-n3 {
    margin-bottom: -1.5rem !important;
  }
  
  .mb-n4 {
    margin-bottom: -2.25rem !important;
  }
  
  .mb-n5 {
    margin-bottom: -4.5rem !important;
  }
  
  .ms-n1 {
    margin-left: -0.375rem !important;
  }
  
  .ms-n2 {
    margin-left: -0.75rem !important;
  }
  
  .ms-n3 {
    margin-left: -1.5rem !important;
  }
  
  .ms-n4 {
    margin-left: -2.25rem !important;
  }
  
  .ms-n5 {
    margin-left: -4.5rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .p-1 {
    padding: 0.375rem !important;
  }
  
  .p-2 {
    padding: 0.75rem !important;
  }
  
  .p-3 {
    padding: 1.5rem !important;
  }
  
  .p-4 {
    padding: 2.25rem !important;
  }
  
  .p-5 {
    padding: 4.5rem !important;
  }
  
  .p-onboarding, .p-6 {
    padding: 1rem 15rem !important;
  }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  
  .px-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  
  .px-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  
  .px-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  
  .px-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  
  .px-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-6 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .py-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  
  .py-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  
  .py-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  
  .py-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  
  .py-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  
  .pt-0 {
    padding-top: 0 !important;
  }
  
  .pt-1 {
    padding-top: 0.375rem !important;
  }
  
  .pt-2 {
    padding-top: 0.75rem !important;
  }
  
  .pt-3 {
    padding-top: 1.5rem !important;
  }
  
  .pt-4 {
    padding-top: 2.25rem !important;
  }
  
  .pt-5 {
    padding-top: 4.5rem !important;
  }
  
  .pe-0 {
    padding-right: 0 !important;
  }
  
  .pe-1 {
    padding-right: 0.375rem !important;
  }
  
  .pe-2 {
    padding-right: 0.75rem !important;
  }
  
  .pe-3 {
    padding-right: 1.5rem !important;
  }
  
  .pe-4 {
    padding-right: 2.25rem !important;
  }
  
  .pe-5 {
    padding-right: 4.5rem !important;
  }

  .pe-6 {
    padding-right: 15rem !important;
  }

  .ps-6 {
    padding-left: 15rem !important;
  }

  .pt-6 {
    padding-top: 6rem !important;
  }

  .pb-6 {
    padding-bottom: 6rem !important;
  }
  
  .pb-0 {
    padding-bottom: 0 !important;
  }
  
  .pb-1 {
    padding-bottom: 0.375rem !important;
  }
  
  .pb-2 {
    padding-bottom: 0.75rem !important;
  }
  
  .pb-3 {
    padding-bottom: 1.5rem !important;
  }
  
  .pb-4 {
    padding-bottom: 2.25rem !important;
  }
  
  .pb-5 {
    padding-bottom: 4.5rem !important;
  }
  
  .ps-0 {
    padding-left: 0 !important;
  }
  
  .ps-1 {
    padding-left: 0.375rem !important;
  }
  
  .ps-2 {
    padding-left: 0.75rem !important;
  }
  
  .ps-3 {
    padding-left: 1.5rem !important;
  }
  
  .ps-4 {
    padding-left: 2.25rem !important;
  }
  
  .ps-5 {
    padding-left: 4.5rem !important;
  }
a {
  cursor: pointer;
    text-decoration: none;
    color: var(--pliable-blue);
}

.pointer {
  cursor: pointer !important;
}


a:hover {
    color: var(--pliable-blue);
    text-decoration: underline;
}

a.force-link {
  cursor: pointer !important;
    text-decoration: none !important;
    color: var(--pliable-blue) !important;
}

a.force-link:hover {
  color: var(--pliable-blue) !important;
    text-decoration: underline !important;
}

a.btn:hover {
    text-decoration: none;
}


h1.page-title {
  margin-bottom: 3rem;
  border-bottom: solid 1px var(--ct-border-color);
  font-size: 36px;
}


.h-100 {
    height: 100% !important;
  }

/* Fix grid */
.row {
    margin-left: -1rem;
    margin-right: -1rem;
}
.row>* {
    padding-right: 1rem;
    padding-left: 1rem;
}

@font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Light.eot");
    src: local("Nunito Light"), local("Nunito-Light"), url("./assets/fonts/Nunito-Light.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-Light.woff") format("woff"), url("./assets/fonts/Nunito-Light.ttf") format("truetype"), url("./assets/fonts/Nunito-Light.svg#Roboto") format("svg");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Regular.eot");
    src: local("Nunito Regular"), local("Nunito-Regular"), url("./assets/fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-Regular.woff") format("woff"), url("./assets/fonts/Nunito-Regular.ttf") format("truetype"), url("./assets/fonts/Nunito-Regular.svg#Roboto") format("svg");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-SemiBold.eot");
    src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("./assets/fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-SemiBold.woff") format("woff"), url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype"), url("./assets/fonts/Nunito-SemiBold.svg#Roboto") format("svg");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Bold.eot");
    src: local("Nunito Bold"), local("Nunito-Bold"), url("./assets/fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/Nunito-Bold.woff") format("woff"), url("./assets/fonts/Nunito-Bold.ttf") format("truetype"), url("./assets/fonts/Nunito-Bold.svg#Roboto") format("svg");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "Fira Code";
    src: url("./assets/fonts/Fira_Code/FiraCode-VariableFont_wght.ttf");
    font-style: normal;
  }

body {
    margin: 0;
    font-family: var(--ct-body-font-family);
    font-size: var(--ct-body-font-size);
    font-weight: var(--ct-body-font-weight);
    line-height: var(--ct-body-line-height);
    color: var(--ct-body-color);
    text-align: var(--ct-body-text-align);
    background-color: rgb(254, 252, 250);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  body.modal-open {
    overflow: hidden;
  }

  .btn, .fake-btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    text-align: center;
    vertical-align: middle;
    font-family: "Poppins";
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.45rem 0.9rem;
    font-size: 0.9rem;
    border-radius: 0.15rem;
    -webkit-transition: brightness 0.15s ease-in-out,color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: brightness 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: brightness 0.15s ease-in-out,color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: brightness 0.15s ease-in-out, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }

  .btn-sm, .btn-group-sm > .btn {
    padding: 0.28rem 0.8rem;
    font-size: 0.875rem;
    border-radius: 0.15rem;
  }

  .btn-xs, .btn-group-xs > .btn {
    padding: 0.2rem 0.7rem;
    font-size: 0.75rem;
    border-radius: 0.12rem;
  }

  a[role="button"] {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

  }

  a[role="button"]:hover {
    text-decoration: underline !important;
  }

  .btn-rounded {
    border-radius: 10px;
  }

  .rounded {
    border-radius: 20px;
  }

  .btn-full-rounded {
    border-radius: 15px;
  }

  .btn:hover {
    filter: brightness(0.85);
  }

  .btn-pliable {
    background-color: #FF9F00;
    color: #fff;
    border-color:  #FF9F00;
  }
  
  .btn-pliable:hover {
    background-color: #e58f00;
    color: #fff;
  
    border-color: #e58f00;
  }


  .btn-dark {
    background-color: var(--pliable-navy);
    color: #fff;
    border-color:  var(--pliable-navy);
  }
  
  /* .btn-dark:hover {
    background-color: #e58f00;
    color: #fff;
  
    border-color: #e58f00;
  } */
  
  .btn-pliable:disabled {
    color: #fff;
    background-color: #c37900;
    border-color: #c37900;
  }

  .btn-pliable-gradient {
    background: linear-gradient(0deg, #E79000, #E79000), linear-gradient(180deg, #FF9F00 0%, #FF7300 179.17%);
    box-shadow: 0px 2px 3px 0px #0000001A;

    box-shadow: 0px 0px 2px 0px #FFFFFF inset;
    border: 0.5px solid #E79000;
    font-family: "Poppins";
    color: white;
  }

  .btn-pliable-gradient:hover {
    color: white;
    cursor: pointer;
  }

  .btn-primary {
    background-color: var(--ct-primary);
    color: #fff;
    border-color:  var(--ct-primary);
  }

  .btn-inverted {
    color: var(--ct-text-muted);
    border: none;
  }

  .btn-inverted.highlight {
    color: var(--pliable-yellow);
  }

  .btn-inverted:hover {
    color: white;
  }

  .btn-inverted:focus {
    outline: none;
    border: none;
  }

  .btn-inverted.highlight:hover {
    color: var(--pliable-yellow) !important;
  }

  .btn-primary:active, .btn-primary:focus {
    background-color: var(--ct-primary);
    color: #fff;
    border-color:  var(--ct-primary);
  }
  
  .btn-primary:hover {
    background-color: #0081b3;
    color: #fff;
  
    border-color: #0081b3;
  }
  
  .btn-primary:disabled {
    color: #fff;
    background-color: #2f8cb1;
    border-color: #2f8cb1;
  }
  
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
  }

  .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }


  .btn-success,   .btn-success:hover {
    color: #fff;
    background-color: var(--ct-success);
    border-color: var(--ct-success);
  }

  .btn-purple,   .btn-purple:hover {
    color: #fff;
    background-color: var(--pliable-purple);
    border-color: var(--pliable-purple);
  }

  .btn-outline-success {
    color: var(--ct-success);
    border-color: var(--ct-success);
  }

  .btn-outline-success:hover {
    background-color: var(--ct-success);
    color: white;
    filter: brightness(1.0);
    border-color: var(--ct-success);
  }

  .btn-outline-pliable {
    color: var(--pliable-yellow);
    border-color: var(--pliable-yellow);
  }

  .btn-outline-pliable:hover {
    background-color: var(--pliable-yellow);
    color: white;
    filter: brightness(1.0);
    border-color: var(--pliable-yellow);
  }


  .btn-outline-danger {
    color: var(--ct-danger);
    border-color: var(--ct-danger);
    background-color: white;
  }

  .btn-outline-danger:hover {
    background-color: var(--ct-danger);
    color: white;
    filter: brightness(1.0);
    border-color: var(--ct-danger);
  }

  .btn-outline-primary {
    color: var(--ct-primary);
    border-color: var(--ct-primary);
    background-color: white;
  }

  .btn-outline-primary:hover {
    background-color: var(--ct-primary);
    color: white;
    filter: brightness(1.0);
    border-color: var(--ct-primary);
  }

  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: var(--ct-success);
    border-color: var(--ct-success);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(3, 3, 3, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
  }
  .btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: var(--ct-success);
    border-color: var(--ct-success);
  }
  .btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
  }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    opacity: 0.75;
  }
  .btn-danger {
    color: #fff;
    background-color: var(--ct-danger);
    border-color: var(--ct-danger);
  }
  .btn-danger:hover {
    color: #fff;
    background-color: var(--ct-danger);
    border-color: var(--ct-danger);
  }

  .btn-light, .btn-light:hover {
    color: #313a46;
    background-color: white;
    border-color: var(--ct-border-color)!important;
  }

  .btn-lg {
    font-size: 18px;
    padding: .5rem 1rem;

  }

  .input-rounded {
    border-radius: 20px;
  }

  .input-lg {
    padding: .6rem 1rem;;
  }

  .btn-huge {
    font-size: 24px;
    padding: 1rem 2rem;
    border-radius: 5px;
  }

  .btn-ghost {
    color: #ccc;
    background-color: white;
    border: dashed 1px #ccc;
  }

  .btn-ghost:hover {
    color: #313a46;
    border-color: #313a46;
    background: white;

    filter: none !important;
  }

  .btn-link {
    color: black;
    font-weight: bold;
    
  }

  .fw-bold {
    font-weight: bold !important;
  }

  .fw-normal {
    font-weight: 400 !important;
  }

  .fw-light {
    font-weight: 200 !important;
  }

  .btn-link:hover {
    cursor: pointer;
    
  }

  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #313a46;
    background-color: #f1f4f8;
    border-color: #f0f3f8;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
  }
  .btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
    color: #313a46;
    background-color: #f1f5f9;
    border-color: #f0f3f8;
  }
  .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(210, 214, 220, 0.5);
  }
  .btn-light:disabled, .btn-light.disabled {
    color: #313a46;
    background-color: #eef2f7;
    border-color: #eef2f7;
  }

  .text-muted {
    --ct-text-opacity: 1;
    color: var(--ct-text-muted) !important;
  }

  .font-11 {
    font-size: 11px;
  }

  .font-13 {
    font-size: 13px;
  }

  .lh-compact {
    line-height: 15px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-24 {
    font-size: 24px;
  }

  .font-36 {
    font-size: 36px;
  }


  .font-48 {
    font-size: 48px;
  }

.content {
    position: absolute;
    top: 70px;
    width: 100%;
}

.section {
    padding: 1rem;
}

.section.spaced {
  padding: 2rem 5rem;
}

.border-bottom {
    border-bottom: solid 1px var(--ct-border-color);
}

.border-right {
    border-right: solid 1px var(--ct-border-color);
}

.border-left {
  border-left: solid 1px var(--ct-border-color);
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.content-inner {
    /* Scroll to keep the header and footer in view */
    overflow-y: auto;

    overflow-x: hidden;

}

.badge {
  line-height: inherit !important;
}

.back-breadcrumb {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 1rem;
}

.content-inner.no-scroll {
  overflow:hidden;
}

.content-inner.has-subnav {
    height: calc(100vh - 100px);
}

.content-inner.has-footer {
  height: calc(100vh - 34px);
}

.content-inner.has-footer.has-subnav {
  height: calc(100vh - 34px - 100px);
}

footer.footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 34px;
  width: 100%;
  background-color: rgb(254, 252, 250);
  line-height: 34px;
  padding-right: .5rem;
  padding-left: .5rem;
}




.arrow-none:after {
    display: none !important;
  }

.flex-column {
  flex-direction: column !important;
}


.flex-1 {
    flex: 1 !important;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.2125em;
    vertical-align: 0.2125em;
    content: "";
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-bottom: 0;
    border-left: 0.25em solid transparent;
  }

  .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-toggle.nocaret::after {
    display: none !important;
  }

  .dropdown.w-100 button {
    width: 100%;
  }
  

.navbar-top {
    padding: 0 12px;
    background-color: white;
    border-bottom: solid 1px var(--ct-border-color);
    min-height: 70px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1001;
  }
  
  .navbar-top.sidebar-compact {
    left: 70px;
  }
  
  .navbar-top.sidebar-disabled {
    left: 0px;
  
  }
  
  .navbar-top .topbar-left {
    background-color: var(--ct-bg-topbar);
    height: 70px;
    position: fixed;
    z-index: 1;
    width: 260px;
    text-align: center;
    top: 0;
    left: 0;
  }
  
  .navbar-top .topbar-left .logo i {
    display: none;
  }
  .navbar-top .topbar-menu {
    position: relative;
    z-index: 1;
  }
  .navbar-top .topbar-menu li {
    float: left;
    max-height: 70px;
  }
  .navbar-top .topbar-menu li .show.nav-link {
    color: #6c757d;
  }
  .navbar-top .topbar-menu .nav-link {
    padding: 0;
    color: #98a6ad;
    min-width: 32px;
    display: block;
    text-align: center;
    margin: 0 10px;
    position: relative;
  }
  .navbar-top .app-search {
    position: static;
    overflow-y: hidden;
  }
  .navbar-top .app-search form {
    padding: calc(32px * 0.5) 5px calc(32px * 0.5) 0;
    overflow: hidden;
    max-width: 320px;
  }
  
  .navbar-top .nav-link.active {
    color: var(--ct-primary);
  }

  /* Notification */
.notification-list {
    margin-left: 0;
  }
  .notification-list .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }
  .notification-list .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: 70px;
  }
  .notification-list .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: #fa5c7c;
  }
  .notification-list .notify-item {
    padding: 10px 20px;
  }
  .notification-list .notify-item.unread-noti {
    background-color: #f3f5fb;
  }
  .notification-list .notify-item.read-noti {
    background-color: transparent;
    border: 1px solid #eef2f7;
  }
  .notification-list .notify-item .card-body {
    padding: 14px;
  }
  .notification-list .notify-item .card-body .noti-close-btn {
    position: absolute;
    top: 3px;
    right: 5px;
  }
  .notification-list .notify-item .card-body .noti-item-title {
    margin: 0 0 2px;
  }
  .notification-list .notify-item .card-body .noti-item-title, .notification-list .notify-item .card-body .noti-item-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .notification-list .notify-item .notify-icon {
    float: left;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
  }
  .notification-list .notify-item .notify-details {
    margin-bottom: 0;
    overflow: hidden;
    margin-left: 45px;
  }
  .notification-list .notify-item .notify-details b {
    font-weight: 500;
  }
  .notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
    display: block;
  }
  .notification-list .notify-item .user-msg {
    margin-left: 45px;
    white-space: normal;
    line-height: 16px;
  }
  .notification-list .topbar-dropdown-menu .notify-item {
    padding: 7px 20px;
  }
  
  .profile-dropdown {
    min-width: 170px;
  }
  .profile-dropdown i, .profile-dropdown span {
    vertical-align: middle;
  }
  
  .profile-dropdown.show {
    right: 0px !important;
  }
  
  .nav-user {
    padding: calc(31px * 0.5) 20px calc(31px * 0.5) 57px !important;
    text-align: left !important;
    position: relative;
    background-color: #fafbfd;
    border: 1px solid #f1f3fa;
    border-width: 0 1px;
    min-height: 70px;
  }
  .nav-user .account-user-avatar {
    position: absolute;
    top: calc(38px * 0.5);
    left: 15px;
  }
  .nav-user .account-user-avatar img {
    height: 32px;
    width: 32px;
  }
  .nav-user .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }
  .nav-user .account-user-name {
    display: block;
    font-weight: 600;
  }

 

  .rounded-circle {
    border-radius: 50% !important;
  }

  .very-rounded {
    border-radius: 10px;
  }

  .avatar-sm {
    height: 3rem;
    width: 3rem;
  }
  
  .avatar-md {
    height: 4.5rem;
    width: 4.5rem;
  }

  .avatar-xs {
    height: 2rem;
    width: 2rem;
  }


  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: var(--ct-modal-backdrop-bg);
  }
  .offcanvas-backdrop.fade {
    opacity: 0;
  }
  .offcanvas-backdrop.show {
    opacity: 0.7;
  }
  
  .offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 50px;
    padding: .75rem;
  }


  .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;

    opacity: 1.0;
    padding: 5px !important;
    border-radius: 5px;
    border: solid 1px #878787;
    width: 16px;
    height: 16px;
    background: none;
    color: #878787;
  }

  .btn-close:hover {
    color: black;
    border-color: black;
  }

  .btn-close::after {
    content: "×";
    line-height: 16px;
    padding: 0px;
    margin: 0px;
    height: 16px;
    display: block;
  }

  #btn-close-orc {
    margin-bottom: 10px;
  }
  
  .offcanvas-title {
    margin-bottom: 0;
    line-height: 20px;
    font-family: "Poppins";
    font-weight: bold;
    
    color: var(--ct-body-color);
    font-size: 18px;
  }
  
  .offcanvas-body {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding: 0px;
    overflow-y: hidden;
  }
  
  .offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid var(--ct-border-color);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .offcanvas-end {
    top: 0;
    right: 0;
    width: 700px;
    /* border-left: 1px solid var(--ct-border-color); */
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  
  .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid var(--ct-border-color);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  
  .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid var(--ct-border-color);
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  
  .offcanvas.show {
    -webkit-transform: none;
            transform: none;
  }

  .table {
    background-color: white;
    border: solid 1px var(--ct-border-color);
    color: var(--ct-body-color);
  }

  .table.no-border {
    border: none;
    border-color: var(--ct-border-color);
  }

  .table.no-border th, .table.no-border tr, .table.no-border td {
    border: none;
  }

  .table.table-action tbody tr:hover {
    background-color: var(--ct-light) !important;
    cursor: pointer;
  }




  .table.no-border tr:last-child td {
    border: none;
  }

  .table.no-border td:first-child, .table.no-border th:first-child {
    padding-left: 0px;
  }

  .table.no-border td:last-child, .table.no-border th:last-child {
    padding-right: 0px;
  }


  .table-fixed {
    table-layout: fixed;
    width: 100%;
  }

  .table-top th, .table-top td {
    vertical-align: top;
  }

  .table-small {
    font-size: 12px;
  }
  


  .table-centered th, .table-centered td {
    vertical-align: middle !important;
  }

  .table>:not(:last-child)>:last-child>* {
    border-bottom-color: var(--ct-border-color);
  }
  

  .form-check {
    display: block;
    min-height: 1.35rem;
    padding-left: 1.612em;
    margin-bottom: 0;
  }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.612em;
  }
  
  .form-check-input {
    cursor: pointer;
    width: 1.112em;
    height: 1.112em;
    margin-top: 0.194em;
    vertical-align: top;
    background-color: var(--ct-form-check-input-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: var(--ct-form-check-input-border);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-print-color-adjust: exact;
            color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  .form-check-input:checked {
    background-color: var(--ct-form-check-input-checked-bg-color);
    border-color: var(--ct-form-check-input-checked-border-color);
  }



  label:not(.form-check-label) {
    font-family: "Poppins";
    font-weight: 500;
    color: black;
    font-size: 16px;
  }



  label.form-label {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }

  label.form-label.small {
    font-size: 12px;
    font-weight: 400;
    color: var(--ct-body-color);
    margin-bottom: 0.25rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins";
  }

  h1 {
    color: black;
    font-weight: 700;
    font-size: 26px;
  }
 h2 {
    font-size: 20px;
    margin-bottom: 1.2rem;
    color: black;
    font-weight: 600;
 }

 h3 {
    font-size: 17px;
    color: black;
    font-weight: 400;
 }

 h4 {
    font-size: 15px;
    color: black;
    font-weight: 600;
 }

  h5 {
    color: black;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: .1em;
  }

 h6 {
    font-size: 12px;
    font-weight: 500;
    color: black;
 }

 .font-poppins {
    font-family: "Poppins";
 }


 nav.subnav {
    background-color: var(--ct-light);
    position: relative;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    /* box-shadow: 0px 5px 10px 0px #0000000D; */
    font-family: "Poppins";
    padding: .25rem .25rem;
  }


  nav.subnav.toolbar .search {
    height: 100%;
    border: none;
    border-left: solid 1px var(--ct-border-color);
    border-right: solid 1px var(--ct-border-color);
    width: 300px;
  }

  nav.subnav.toolbar .search input {
    border: none;
    padding: 0px .5rem;
    width: 100%;
    font-size: 13px;
    line-height: 39px;
  }

  nav.subnav.toolbar .search input:focus {
    outline: none;
  }

  input.search-input {
    font-size: 14px;
    width: 100%;
    border-radius: 20px;
    border: solid 1px #ccc;
    padding: .5rem 1rem;
    margin-right: .5rem;
  }

  nav.subnav .actions {
    flex: 1;
    text-align: right;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  nav.subnav .actions .btn {
    padding: 0px 2rem;
    height: 32px;
    line-height: 32px;

  }

  nav.subnav .nav-link {
    color: #888;
    font-size: 13px;
    line-height: 25px;
    border-radius: 5px;
    padding: 0px 1.5rem;
    margin-right: 1rem;
  }

  nav.subnav .nav-link.active {
    color: black;
  }

  nav.subnav .nav-link.highlight {
    background-color: var(--ct-highlight);
  }

  nav.subnav .nav-link.highlight.active {
    background-color: white;
  }
  
  nav.subnav .nav-link:hover {
    color: black;
    text-decoration: none;
  }

  

  
  nav.subnav.toolbar .nav-link {
    
    padding-top: 0px;
    padding-bottom: 0px;
    height: 32px;
    line-height: 32px;
    position: relative;
    border-radius: 8px;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    border-top: solid 1px transparent;
    margin-top: 9px;
    margin-left: .25rem;
    margin-right: .25rem;
    padding-left: .75rem;
    padding-right: .75rem;
  }


  nav.subnav.toolbar .nav-link.active {
    height: 36px;
    color: var(--ct-menu-item);
    background-color: white;
    border-left: solid 1px var(--ct-border-color);
    border-right: solid 1px var(--ct-border-color);
    border-top: solid 1px var(--ct-border-color);
    z-index: 2;
    border-bottom: solid 1px white;
    box-shadow: 0px 1px 6px 2px #ccc;
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  

  nav.subnav .nav-link  .subnav-drawer {
    display: none;
    padding: 1rem;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  nav.subnav .nav-link .shadow-hider {
    position: absolute;
    top: 28px;
    box-shadow: none;
    height: 10px;
    left: 0px;
    width: 100%;
    background-color: white;
    z-index: 2;

    display: none;
  }

  nav.subnav .nav-link.active .shadow-hider {
    display: block;
  }

nav.subnav .nav-link.active .subnav-drawer {
  display: block;
  position: absolute;
  top: 35px;
  left: -1px;
  background-color: white;
  width: 650px;
  border-bottom: solid 1px var(--ct-border-color);
  border-left: solid 1px var(--ct-border-color);
  border-right: solid 1px var(--ct-border-color);
  z-index: 1;
  box-shadow: 0px 1px 6px 2px #ccc;
}
  


  .inline-edit {
    border: solid 1px transparent;
    outline: none;
    resize: none;
    font-weight: 600;
    background: transparent;
    padding: 0px;
  }

  
  .inline-edit:hover, .inline-edit:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: transparent;
    border-bottom: solid 1px var(--ct-border-color);
  }

  .inline-edit:focus, .inline-edit:focus-visible {
    border-bottom: solid 1px #00A1E0;

  }


  
  .form-switch .form-check-label {
    margin-left: 0.5rem;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .d-flex {
    display: flex !important;
  }

  .d-inline-block {
    display: inline-block;
  }

  .d-flex.center-vertically {
    align-items: center;
  }

  .input-code {
    font-family: monospace;
    font-size: 13px;
    line-height: 24px;
  }

  .font-code {
    font-family: "Fira Code", monospace;

  }

  .float-right {
    float: right;
  }



  .was-validated .form-control:valid, .form-control.is-valid {
    border-color: #0acf97;
    padding-right: calc(1.5em + 0.9rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.225rem) center;
    background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
  }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #0acf97;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  }
  
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.9rem);
    background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem);
  }
  
  .was-validated .form-select:valid, .form-select.is-valid {
    border-color: #0acf97;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.95rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.9rem center, center right 2.7rem;
    background-size: 14px 10px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
  }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #0acf97;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  }
  
  .was-validated .form-check-input:valid, .form-check-input.is-valid {
    border-color: #0acf97;
  }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #0acf97;
  }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
  }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #0acf97;
  }


  i.floating-icon {
    position: absolute;
    right: 1.5rem;
    font-size: 2rem;
    opacity: 0.3;
  }




  .modal-title.h4 {
    margin-bottom: 0;
    line-height: 20px;
    font-family: "Poppins";
    font-weight: 600;
    color: #000;
    font-size: 18px;
  }

.nav-tabs {
  border-bottom: solid 4px var(--ct-border-color);
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: solid 4px var(--ct-border-color);

  /* Animate border color */
  transition: border-bottom-color 0.2s ease-in-out, color 0.2s ease-in-out;
  font-family: "Poppins";
  font-weight: 600;
  color: var(--ct-body-color);
  margin-bottom: -4px;
  padding-bottom: 2px;


}

.nav-tabs .nav-link:hover {
  color: black;
  border-bottom: solid 4px #888;
}

.nav-tabs .nav-link.active {
  color: black;
  border-bottom: solid 4px #FF9F00;
  background-color: inherit !important;
}

.accordion-header {
  user-select: none;
}

.accordion-button {
  padding: .5rem 1rem;
  font-weight: 600;
  font-size: 14px;
  color: black;
  background-color: white;
  
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #eee;
}

.accordion-button:focus {
  box-shadow: none;
}

.drawer.offcanvas .offcanvas-body {
  overflow-y: hidden;
}

.drawer.offcanvas .offcanvas-body .tab-content, .drawer.offcanvas .offcanvas-body .tab-content .tab-pane {
  height: calc(100vh - 240px);
}
.drawer.offcanvas .offcanvas-body .tab-content .scrollable-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

input.match-with-dropdown {
  line-height: 1.75rem;
}

div.tab-content, div.tab-pane {
  height: 100%;
}


@keyframes spin-and-fade {
  0% {
      transform:rotate(0deg);
      opacity: 1.0;
  }
  50% {
      transform:rotate(360deg);
      opacity: 1.0;
  }
  100% {
    opacity: 0.0;
    transform:rotate(360deg);
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.spin-2s:before {
  animation-name: spin-and-fade;
  animation-duration: 2000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.shake-1s {
  animation-name: shake;
  animation-duration: 300ms;
  animation-iteration-count: 3;
  animation-timing-function: ease-in-out;
}

.modal.fullscreen {
  padding: 0px !important;
}
.fullscreen .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0px;
}

.fullscreen .modal-dialog .modal-content {
  height: 100%;
}

@keyframes grow-slightly {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}

.grecaptcha-badge { 
  visibility: hidden !important;
}


button.icon-button {
  background: none;
  border: none;
  color: var(--ct-body-color);
  user-select: none;
  padding: 0px;

}

.dropdown a[role="button"]:hover {
  text-decoration: none !important;
}

.bg-primary button.icon-button {
  color: white !important;
}

button.icon-button:hover, button.icon-button:active {
  color: black;
}

button.icon-button.highlight {
  color: var(--pliable-yellow);
}


.more-rounded {
  border-radius: 10px !important;
}


.modal-video {
  max-width: none !important;
  width: 885px;
}

.no-radius {
  border-radius: 0px !important;
}

.btn:disabled {
  opacity: .25;
}

@keyframes animated-path {
  to {
    stroke-dashoffset: -103;
  }
}


.popover {
  border: solid 1px var(--ct-border-color);
  border-radius: 5px;
  background-color: white;

  box-shadow: 0px 1px 6px 2px #ccc;
  max-width: 400px !important;
}

.popover-header {
  background-color: white;
  font-family: "Poppins";
  font-weight: 600;
  color: black;
}


.Toastify__toast {
  padding: 0px !important;
}

.Toastify__toast-body {
  margin: 0px !important;
  padding: 0px !important;
  background-color: var(--pliable-navy);
}

.toast-header .btn-close {
  color: black;
  border-color: black;
  background: white;
}

.toast-header .btn-close:hover {
  cursor: pointer;
  background-color: var(--ct-border-color);
}

.zsh-menu {
  z-index: 1001;
}

h1.sidebar-section-header {
  background-color: #272727;
  color: white;
  padding: 8px 12px;
  text-transform: uppercase;
  margin: 0px;
  font-size: 18px;

}

h2.sidebar-section-header {
  background-color: #272727;
  color: white;
  padding: 8px 12px;
  text-transform: uppercase;
  margin: 0px;
  font-size: 14px;

}

#thena-iframe-element {
  position: fixed !important; bottom: -50px !important;
}

.intercom-lightweight-app {
  visibility: hidden !important;
}

.overflow-ellipsis,
.overflow-ellipsis > * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.disabled {
  pointer-events: none !important;
  opacity: .66 !important;
}

.hidden {
  visibility: hidden !important;
}

ul.nav .nav-link {
  text-align: left;
}

ul.nav .nav-item .nav-item .nav-link {
  font-size: 13px;
  padding: .25rem 1rem .25rem 2rem;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: white;
}

.clickable {
  cursor: pointer !important;
  opacity: 0.8;

}

.clickable:hover {
  opacity: 1.0;
}


.dropdown-item {
  font-size: 14px;
  padding: .25rem 1rem;
}

.dropdown-item:active {
  background-color: var(--ct-primary);
}


div.card-body {
  padding: 2rem;
}

div.empty-state {

  background: var(--ct-light);
  color: var(--ct-body-color);
  padding: 3rem;
  border: solid 1px var(--ct-border-color);
}